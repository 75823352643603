export const family = [
    {
        name: "Mum",
        message: "Corey. The wee cool dude. Such a happy baby, loved the hoovering and washing machine. Keep being you ❤️"
    },
    {
        name: "Dad",
        message: "Corey son, you always seemed to live on the bright side of life when you were a kid. I hope you always keep that son. Love dad. Xxx",
    },
    {
        name: "Dylan",
        message: "Happy birthday my brother! Finally 21 let's gooo - catching up with us adults now... Keep reaching for the stars, I'll never stop believing in you ❤️ Love you millions"
    },
    {
        name: "Lewys",
        message: "Happy birthday to my little brother and one of the best friends I could ask for. Enjoy the night like it's your last, will make it worth every minute. Love you 💚 xxx"
    },
    {
        name: "Amber",
        message: "happy birthday brother! you are the blueprint C: enjoy 21 that's crazy! love ya ♡"
    },
    {
        name: "Lily",
        message: "Woof. Playful growl. Some other dog noises..."
    },
    {
        name: "Coco",
        message: "Happy 21st Birthday Corey!! I hope you have a fantastic day and superb year being 21. Halfway to 42 now woohoo! Live, laugh and love it up bro 😎 Lots of love, Coco xo"
    },
    {
        name: "Nan and Robert",
        message: "You were a beautiful wee boy and now you have become a handsome young man. Happy 21st Corey. Love you to the moon and back. Nan and Robert xoox"
    },
    {
        name: "Aunty K",
        message: "Can't believe my youngest nephew is 21, I feel so old lol. Have the best day whatever you do. Lots of love Aunty K x"
    },
    {
        name: "Sylvia",
        message: "Hi Corey Happy 21st Birthday. Hope you have a great day. Can't believe you're that age already. Doesn't seem that long ago you were coming to my place to play. I have so many happy memories of you as a wee boy. I wish you all the best in life. Take care, love your friend, Sylvia xxxx"
    },
    {
        name: "Aunties (Allan & James)",
        message: "Happy birthday Corey, have a magical weekend. Love from yer aunties ❤️"
    }
];

export const friends = [
    {
        name: "JP",
        message: "Happy birthday Corey!! To another year of Ingram madness love you mega my gggg"
    },
    {
        name: "Wallace",
        message: "Happy 21st bro hope you have an amazing day. Let's make it one for the books. Love Wallace ❤️",
    },
    {
        name: "Ryan",
        message: `Dear Corey, ma brotha, wishing a happy birthday to one of the coolest cats I've ever had the pleasure of being friends with. Happy big two one!! Praise the sun \\o/ Love you loads xxx`
    },
    {
        name: "Gavin",
        message: "Happy Birthday handsome, lots of love from Gavin xx"
    },
    {
        name: "Aaron",
        message: "Happy bday Corey have a pint on me because you're a stand up guy."
    },
    {
        name: "Anthony",
        message: "Happy birthday Corey mate yer some bit a kit and always up for a good time. To many more years of you being you mate."
    },
    {
        name: "Erin",
        message: "Happy birthday Corey!! I hope that 21 brings you so much happiness and good times ❤️ Sending my bestest wishes your way, can't wait to celebrate with you, lots of love from Erin ^-^"
    },
    {
        name: "AJ",
        message: "Happy Birthday Corey ma boi. Carpe Diem"
    },
    {
        name: "Jason",
        message: "お誕生日おめでとうロングディックキング Happy birthday king"
    },
    {
        name: "Ronan",
        message: "Have a good one shagger"
    },
    {
        name: "Cian",
        message: "Hi mrs! Hope you have a bday as wonderful as your fine self, will hopefully see you at the weekend 2 celebrate! Love you lots :P. Cian"
    },
    {
        name: "Abby",
        message: "Happy 21st to thee Corey Black ❤️ Can't wait to celebrate with you and here's to many more fun n fried times togetha ! Luv u. Abby xxx"
    },
    {
        name: "Haggy",
        message: "Happy 21st Birthday Corey boy, hope you have a cracker mate 😎"
    },
    {
        name: "Chris",
        message: "Happy birthday to my brother"
    },
    {
        name: "Tina",
        message: "Happy 21st Corey, hope ya have a blast :) love from Tina ❤️"
    },
    {
        name: "Keir",
        message: "Happy birthday bruver, hopes it's a good one, love you xx"
    },
    {
        name: "Benji",
        message: "To kawrae, big happy birthday to the chillest/soundest mayte. Hope you have a banging day. You're now legal in Bahrain ;) ya wee szmata."
    },
    {
        name: "Lucy",
        message: "Corey, Happy 21st birthday angel!! Can't wait to celebrate with you properly soon, but in the meantime have an amazing day ❤️ You totally deserve it, enjoy yourself!! Big love n miss you!!"
    },
    {
        name: "Skye and Iona",
        message: "Happy 21st goat hope your day's amazin because you deserve it 👑 from Skye and Iona ❤️"
    },
    {
        name: "Dnut",
        message: "Thank you for putting up with me talking over you, I love you"
    },
    {
        name: "Ewan",
        message: "Happy 21st Corey, have a class day brother lots of love Ewan X"
    },
    {
        name: "Adam",
        message: "Happy birthday Corey! Have a good day mate."
    },
    {
        name: "Tomei",
        message: "Happy Birthday Corey Black ya wee sexy bastard. Have a good day and thanks for letting me stay over at yours many many times after many many mad wans 🥴 Hope all is well and I'll buy you a pint next time I see you 🍻 xxx"
    },
    {
        name: "Liam",
        message: "Yes Corey happy 21st bro, making me feel old 😂 hope you've had a belter and hopefully see you soon. Take it easy. From Liam 😎 x"
    }
];