import React from 'react';
import Rellax from "rellax";

import {
    Button,
    Card,
    CardBody,
    UncontrolledCollapse,
    FormGroup,
    Form,
    Input,
    NavbarBrand,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators,
  } from "reactstrap";

export default function Header () {
    const videoRef = React.createRef();

    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    
        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
          setTimeout(function () {
            new Rellax(".rellax", {
              center: true,
            });
          }, 5000);
          new Rellax(".rellax-header");
          new Rellax(".rellax-text");
        }
    
        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);
    
        return function cleanup() {
          document.body.classList.remove("presentation-page");
          document.body.classList.remove("sidebar-collapse");
    
          // we need to remove the script when we change the page
          script.parentNode.removeChild(script);
        };
      });

      return (
        <>
          <div className="page-header">
            <div className="rellax-header" data-relax-speed="-4">
                <div
                    className="video-image"
                    style={{
                        backgroundImage: "url(" + require("../assets/image/paris.jpg") + ")",
                        // backgroundImage: "url(" + require("../assets/image/video-placeholder.png") + ")",
                    }}
                />
                <video
                    id="video-source"
                    loop="loop"
                    muted="muted"
                    preload="auto"
                    volume="0"
                    autoPlay
                    ref={videoRef}
                >
                    <source
                        src={require("../assets/video/fireworks.mp4")}
                        type="video/mp4"
                    ></source>
                    Video not supported
                </video>
              </div>
            <div className="rellax-text-container rellax-text">
              <h1 className="h1-seo" data-rellax-speed="-1">
                COREY
              </h1>
              <div className="pro">kawrae</div>
            </div>
            <h3 className="h3-description rellax-text" data-rellax-speed="-1">
              Happy 21st Birthday
            </h3>
            {/* <h6
              className="category category-absolute rellax-text"
              data-rellax-speed="-1"
            >
              Designed by{" "}
              <a href="http://invisionapp.com/?ref=creativetim" target="_blank">
                <img
                  alt="..."
                  className="invision-logo"
                  src={require("assets/img/invision-white-slim.png")}
                ></img>
              </a>
              . Coded by{" "}
              <a
                href="https://www.creative-tim.com?ref=nuk-pro-react-presentation-header"
                target="_blank"
              >
                <img
                  alt="..."
                  className="creative-tim-logo"
                  src={require("assets/img/creative-tim-white-slim2.png")}
                ></img>
              </a>
              .
            </h6> */}
          </div>
        </>
      );
};