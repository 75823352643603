import React from 'react';

import {
    Card,
    CardTitle,
    CardBody,
    Container,
    Row,
    Col,
  } from "reactstrap";

import { family, friends } from '../data';

export default function Messages() {
    return (
        <>
            <div className="cd-section" id="testimonials" data-background-color="dark-blue">
                <div className="testimonials-3">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="6">
                                <h2 className="title">From family...</h2>
                            </Col>
                        </Row>
                        {
                            family.map(item => {
                                return (
                                    <Row>
                                        <Col md={4} />
                                        <Col md={4}>
                                            <Card className="card-testimonial card-plain">
                                                <CardBody>
                                                    <CardTitle tag="h3">{item.name}</CardTitle>
                                                    <h6 className="category text-info">Happy 21st Birthday</h6>
                                                    <p className="card-description">{item.message}</p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={4} />
                                    </Row> 
                                )
                            })
                        }
                    </Container>
                </div>
            </div>
            <div className="cd-section" id="testimonials" data-background-color="dark-blue">
                <div className="testimonials-3">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="6">
                                <h2 className="title">From friends...</h2>
                            </Col>
                        </Row>
                        {
                            friends.map(item => {
                                return (
                                    <Row>
                                        <Col md={4} />
                                        <Col md={4}>
                                            <Card className="card-testimonial card-plain">
                                                <CardBody>
                                                    <CardTitle tag="h3">{item.name}</CardTitle>
                                                    <h6 className="category text-info">Happy 21st Birthday</h6>
                                                    <p className="card-description">{item.message}</p>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={4} />
                                    </Row> 
                                )
                            })
                        }
                    </Container>
                </div>
            </div>
        </>
    )
};