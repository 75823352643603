import React from 'react';

import { Container } from "reactstrap";

export default function Footer () {
    return (
        <>
            <footer className="footer" data-background-color="black">
                <Container>
                    <nav>
                        <ul>
                        <li>
                            <a
                            // href="https://www.creative-tim.com?ref=nuk-pro-react-footer"
                            // target="_blank"
                            >
                            HAPPY BIRTHDAY BROTHER
                            </a>
                        </li>
                        {/* <li>
                            <a
                            href="http://presentation.creative-tim.com?ref=nuk-pro-react-footer"
                            target="_blank"
                            >
                            About Us
                            </a>
                        </li>
                        <li>
                            <a
                            href="http://blog.creative-tim.com?ref=nuk-pro-react-footer"
                            target="_blank"
                            >
                            Blog
                            </a>
                        </li> */}
                        </ul>
                    </nav>
                    <div className="copyright" id="copyright">
                        - Dylan ❤️
                    </div>
                </Container>
            </footer>
        </>
    )
};