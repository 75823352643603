import React from 'react';

import {
    Card,
    Container,
    Row,
    Col,
} from "reactstrap";

export default function Collage () {
    let images = [];
    for (let i = 0; i < 54; ++i) {
        images.push({
            key: i,
            source: <img
                        alt="..."
                        className="img img-raised rounded"
                        src={require(`assets/image/collage/collage_${i}.jpg`)}
                    ></img>
        })
    }

    // return (
    //     <>
    //       <div
    //         className="section section-cards"
    //         data-background-color="gray"
    //         id="cards"
    //       >
    //         <div className="cards">
    //           <Container>
    //             <div className="title">
    //               <h3 className="title">Cards</h3>
    //               <h3>
    //                 <small>Blog Cards</small>
    //               </h3>
    //             </div>
    //             <Row>
    //               <Col lg="4" md="6">
    //                 <Card data-background-color="red">
    //                   <CardBody>
    //                     <h6 className="category-social">
    //                       <i className="fa fa-fire"></i> Trending
    //                     </h6>
    //                     <p className="card-description">
    //                       "The supreme art of war is to subdue the enemy without
    //                       fighting."
    //                     </p>
    //                     <CardFooter>
    //                       <div className="author">
    //                         <img
    //                           alt="..."
    //                           className="avatar img-raised"
    //                           src={require("assets/img/julie.jpg")}
    //                         ></img>
    //                         <span>Susan B. Anthony</span>
    //                       </div>
    //                       <div className="stats stats-right">
    //                         <i className="now-ui-icons ui-2_favourite-28"></i>
    //                         10.4K ·{" "}
    //                         <i className="now-ui-icons files_single-copy-04"></i>
    //                         425
    //                       </div>
    //                     </CardFooter>
    //                   </CardBody>
    //                 </Card>
    //                 <Card className="card-blog">
    //                   <div className="card-image">
    //                     <img
    //                       alt="..."
    //                       className="img rounded"
    //                       src={require("assets/img/project13.jpg")}
    //                     ></img>
    //                   </div>
    //                   <CardBody>
    //                     <h6 className="category text-warning">
    //                       <i className="now-ui-icons business_bulb-63"></i> Focus
    //                     </h6>
    //                     <CardTitle tag="h5">
    //                       <a href="#pablo" onClick={(e) => e.preventDefault()}>
    //                         Stay Focused: Train Your Brain
    //                       </a>
    //                     </CardTitle>
    //                     <p className="card-description">
    //                       Our brains are finely attuned to distraction, so today's
    //                       digital environment makes it especially hard to focus...
    //                     </p>
    //                   </CardBody>
    //                 </Card>
    //                 <Card>
    //                   <CardBody>
    //                     <h6 className="category text-danger">
    //                       <i className="now-ui-icons media-2_sound-wave"></i>{" "}
    //                       Trending
    //                     </h6>
    //                     <CardTitle tag="h5">
    //                       <a href="#pablo" onClick={(e) => e.preventDefault()}>
    //                         Here Be Dragons
    //                       </a>
    //                     </CardTitle>
    //                     <p className="card-description">
    //                       An immersive production studio focused on virtual reality
    //                       content, has closed a $10 million Series A round led by
    //                       Discovery Communications
    //                     </p>
    //                     <CardFooter>
    //                       <div className="author">
    //                         <img
    //                           alt="..."
    //                           className="avatar img-raised"
    //                           src={require("assets/img/olivia.jpg")}
    //                         ></img>
    //                         <span>Lord Alex</span>
    //                       </div>
    //                       <div className="stats stats-right">
    //                         <i className="now-ui-icons ui-2_favourite-28"></i>
    //                         342 ·{" "}
    //                         <i className="now-ui-icons files_single-copy-04"></i>
    //                         45
    //                       </div>
    //                     </CardFooter>
    //                   </CardBody>
    //                 </Card>
    //               </Col>
    //               <Col lg="4" md="6">
    //                 <Card data-background-color="black">
    //                   <CardBody className="content-danger">
    //                     <h6 className="category-social">
    //                       <i className="fab fa-apple"></i> New Apps
    //                     </h6>
    //                     <CardTitle tag="h4">
    //                       <a href="#pablo" onClick={(e) => e.preventDefault()}>
    //                         FiftyThree Files For Paper
    //                       </a>
    //                     </CardTitle>
    //                     <p className="card-description">
    //                       Yesterday, as Facebook launched its news reader app Paper,
    //                       design-focused startup FiftyThree called out Facebook
    //                       publicly for using their brand name...
    //                     </p>
    //                     <CardFooter className="text-center">
    //                       <Button
    //                         className="btn-round"
    //                         color="default"
    //                         href="#pablo"
    //                         onClick={(e) => e.preventDefault()}
    //                       >
    //                         Read Article
    //                       </Button>
    //                     </CardFooter>
    //                   </CardBody>
    //                 </Card>
    //                 <Card className="card-blog">
    //                   <div className="card-image">
    //                     <a href="#pablo" onClick={(e) => e.preventDefault()}>
    //                       <img
    //                         alt="..."
    //                         className="img rounded"
    //                         src={require("assets/img/card-blog2.jpg")}
    //                       ></img>
    //                     </a>
    //                   </div>
    //                   <CardBody>
    //                     <h6 className="category text-info">Features</h6>
    //                     <CardTitle tag="h5">
    //                       That’s One Way To Ditch Your Passenger
    //                     </CardTitle>
    //                     <p className="card-description">
    //                       As near as we can tell, this guy must have thought he was
    //                       going over backwards and tapped the rear break to bring
    //                       the nose down...
    //                     </p>
    //                     <CardFooter>
    //                       <div className="author">
    //                         <img
    //                           alt="..."
    //                           className="avatar img-raised"
    //                           src={require("assets/img/julie.jpg")}
    //                         ></img>
    //                         <span>Mike John</span>
    //                       </div>
    //                       <div className="stats stats-right">
    //                         <i className="now-ui-icons tech_watch-time"></i>5 min
    //                         read
    //                       </div>
    //                     </CardFooter>
    //                   </CardBody>
    //                 </Card>
    //                 <Card data-background-color="blue">
    //                   <CardBody>
    //                     <h6 className="category-social">
    //                       <i className="fab fa-twitter"></i> Twitter
    //                     </h6>
    //                     <p>
    //                       "You Don't Have to Sacrifice Joy to Build a Fabulous
    //                       Business and Life"
    //                     </p>
    //                     <CardFooter>
    //                       <div className="author">
    //                         <img
    //                           alt="..."
    //                           className="avatar img-raised"
    //                           src={require("assets/img/james.jpg")}
    //                         ></img>
    //                         <span>Tania Andrew</span>
    //                       </div>
    //                       <div className="stats stats-right">
    //                         <i className="now-ui-icons ui-2_favourite-28"></i>
    //                         2.4K ·{" "}
    //                         <i className="now-ui-icons files_single-copy-04"></i>
    //                         45
    //                       </div>
    //                     </CardFooter>
    //                   </CardBody>
    //                 </Card>
    //               </Col>
    //               <Col className="offset-md-2 offset-lg-0" lg="4" md="8">
    //                 <Card className="card-blog">
    //                   <div className="card-image">
    //                     <a href="#pablo" onClick={(e) => e.preventDefault()}>
    //                       <img
    //                         alt="..."
    //                         className="img rounded"
    //                         src={require("assets/img/examples/card-blog6.jpg")}
    //                       ></img>
    //                     </a>
    //                   </div>
    //                   <CardBody className="text-center">
    //                     <h6 className="category text-danger">
    //                       <i className="now-ui-icons media-2_sound-wave"></i>{" "}
    //                       Business
    //                     </h6>
    //                     <CardTitle tag="h5">
    //                       Axel Springer Spends $343M To Buy Business Insider
    //                     </CardTitle>
    //                     <p className="card-description">
    //                       German media giant Axel Springer has announced it’s
    //                       acquiring online business news publication Business
    //                       Inside...
    //                     </p>
    //                     <CardFooter>
    //                       <Button
    //                         color="info"
    //                         href="#pablo"
    //                         onClick={(e) => e.preventDefault()}
    //                       >
    //                         Read Article
    //                       </Button>
    //                     </CardFooter>
    //                   </CardBody>
    //                 </Card>
    //                 <Card className="card-blog">
    //                   <div className="card-image">
    //                     <img
    //                       alt="..."
    //                       className="img rounded"
    //                       src={require("assets/img/card-blog3.jpg")}
    //                     ></img>
    //                   </div>
    //                   <CardBody>
    //                     <h6 className="category text-warning">
    //                       <i className="now-ui-icons media-1_camera-compact"></i>{" "}
    //                       Photo
    //                     </h6>
    //                     <CardTitle tag="h5">
    //                       <a href="#pablo" onClick={(e) => e.preventDefault()}>
    //                         Indispensible to nature photography: the hide
    //                       </a>
    //                     </CardTitle>
    //                     <CardFooter>
    //                       <div className="stats stats-right">
    //                         <i className="now-ui-icons ui-2_favourite-28"></i>
    //                         342 ·{" "}
    //                         <i className="now-ui-icons files_single-copy-04"></i>
    //                         45
    //                       </div>
    //                       <div className="author">
    //                         <img
    //                           alt="..."
    //                           className="avatar img-raised"
    //                           src={require("assets/img/james.jpg")}
    //                         ></img>
    //                         <span>Devin Coldewey</span>
    //                       </div>
    //                     </CardFooter>
    //                   </CardBody>
    //                 </Card>
    //               </Col>
    //             </Row>
    //           </Container>
    //         </div>
    //       </div>
    //     </>
    //   );

    return (
    <>
      <div className="cd-section" id="blogs">
        <div className="blogs-4" id="blogs-4">
          <Container>
            <Row>
                <Col className="ml-auto mr-auto text-center mr-5" md="8">
                  <h2 className="title">Say cheese!</h2>
                  <h4 className="description">
                    Check out those pearly whites
                  </h4>
                  <br></br>
                </Col>
              </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                {
                    images.map(item => {
                        return (
                            <Card className="card-plain card-blog">
                                <div className="card-image">
                                    {item.source}
                                </div>
                            </Card>
                        )
                    })
                }
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};