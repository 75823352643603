import React from 'react';
import { Container, Row, Col } from "reactstrap";

export default function Messages () {

    return (
        <>
          <div className="section section-basic-components">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="5" md="7">
                  <h2 className="title">Corey Black</h2>
                  <h6 className="category">Who is this wonderful person?</h6>
                  <h5 className="description">
                    The man, the myth, the legend. <br></br>
                    The life of any party, the laid-back rockstar. <br></br>
                    He's the one to be and the one to know.
                  </h5>
                </Col>
                <Col lg="6" md="12">
                  <div className="image-container">
                    <img
                      alt="..."
                      className="components-macbook"
                      src={require("assets/image/showcase/showcase_1.jpg")}
                    ></img>
                    <img
                      alt="..."
                      className="table-img"
                      src={require("assets/image/showcase/showcase_2.jpg")}
                    ></img>
                    <img
                      alt="..."
                      className="coloured-card-btn-img"
                      src={require("assets/image/showcase/showcase_3.jpg")}
                    ></img>
                    <img
                      alt="..."
                      className="coloured-card-img"
                      src={require("assets/image/showcase/showcase_4.png")}
                    ></img>
                    <img
                      alt="..."
                      className="social-img"
                      src={require("assets/image/showcase/showcase_5.jpg")}
                    ></img>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section section-cards">
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" md="8">
              <div className="section-description">
                <h2 className="title">kawrae</h2>
                <h6 className="category">Today is all about you</h6>
                <h5 className="description">
                  Everybody around you have contributed to this site.<br></br> You are adored by the masses. 
                  {/* From cards designed for blog posts, to product cards or user
                  profiles, you will have many options to choose from. All the
                  cards follow the Now UI Kit style principles and have a design
                  that stands out. We have gone above and beyond with options
                  for you to organise your information. */}
                </h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="images-container">
                <div className="image-container1 mr-1">
                  <img
                    alt="..."
                    src={require("assets/image/showcase/showcase_6.jpg")}
                  ></img>
                </div>
                <div className="image-container2 mr-1">
                  <img
                    alt="..."
                    src={require("assets/image/showcase/showcase_7.jpg")}
                  ></img>
                </div>
                
                <div className="image-container4 mr-1">
                  <img
                    alt="..."
                    src={require("assets/image/showcase/showcase_8.jpg")}
                  ></img>
                </div>
                <div className="image-container5">
                  <img
                    alt="..."
                    src={require("assets/image/showcase/showcase_9.jpg")}
                  ></img>
                </div>
                <div>
                  <img
                    alt="..."
                    src={require("assets/image/showcase/showcase_10.jpg")}
                  ></img>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
        </>
      );
};