import React from 'react';

import Header from './components/Header';
import Blurb from './components/Blurb';
import Messages from './components/Messages';
import Collage from './components/Collage';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Header />
      <Blurb />
      <Messages />
      <Collage />
      <Footer />
    </>
  );
}

export default App;
